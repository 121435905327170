import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showLogin: false, // 是否显示登录弹窗
    userInfo: JSON.parse(localStorage.getItem('USER_INFO')) || {}, // 用户信息
    isWeChatAuth: false, // 是否进行了微信授权
    openid: '', // 授权后返回的openid
    applyInfo: JSON.parse(localStorage.getItem('APPLY_INFO')) || {}, // 报名的核销信息
    meditationApplyInfo: JSON.parse(localStorage.getItem('MEDITATION_APPLY_INFO')) || {} // 冥想小程序报名的核销信息
  },
  getters: {},
  mutations: {
    UPDATE_SHOW_LOGIN(state, payload) {
      state.showLogin = payload
    },
    UPDATE_USERINFO(state, payload) {
      state.userInfo = payload
      localStorage.setItem('USER_INFO', JSON.stringify(payload))
    },
    CLEAR_USERINFO(state) {
      state.userInfo = {}
      localStorage.removeItem('USER_INFO')
    },
    UPDATE_IS_WECAHT_AUTH(state, payload) {
      state.isWeChatAuth = payload
    },
    UPDATE_OPENID(state, payload) {
      state.openid = payload
    },
    UPDATE_APPLY_INFO(state, payload) {
      state.applyInfo = payload
      localStorage.setItem('APPLY_INFO', JSON.stringify(payload))
    },
    CLEAR_APPLY_INFO(state) {
      state.applyInfo = {}
      localStorage.removeItem('APPLY_INFO')
    },
    UPDATE_MEDITATION_APPLY_INFO(state, payload) {
      state.meditationApplyInfo = payload
      localStorage.setItem('MEDITATION_APPLY_INFO', JSON.stringify(payload))
    },
    CLEAR_MEDITATION_APPLY_INFO(state) {
      state.meditationApplyInfo = {}
      localStorage.removeItem('MEDITATION_APPLY_INFO')
    }
  },
  actions: {},
  modules: {}
})
