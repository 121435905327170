var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-bg"}),_c('img',{staticClass:"logo",attrs:{"src":require("@/apps/activity/assets/img/logo_big.png"),"alt":""}}),_c('div',{staticClass:"title"},[_vm._v("YUREN瑜伽")]),_c('van-form',{staticClass:"form",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form-label"},[_vm._v("手机号码：")]),_c('van-field',{staticClass:"form-field",attrs:{"name":"mobile","rules":[
        {
          validator(val) {
            return /^1\d{10}$/.test(val)
          },
          message: '请输入正确的手机号码',
          required: true
        }
      ]},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),_c('div',{staticClass:"form-tip-title"},[_vm._v("温馨提示：")]),_c('div',{staticClass:"form-tip-text"},[_vm._v("为了确保您是本人操作，请输入报名手机号进行验证，验证成功后方可检验二维码")]),_c('van-button',{staticClass:"form-btn",attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("确认")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }