import { wxJsSdkSignatureAPI } from '@/apps/activity/api/user.js'

let wxJsSdkScript

/**
 * @param {*} config
 * @param config.jsApiList
 * @param config.openTagList
 */
export async function getWxJsSdk(config) {
  try {
    if (!wxJsSdkScript) {
      await loadWxSdk()
    }
    const url = location.href
    const res = await wxJsSdkSignatureAPI({ url })
    return await configWxSdk(res.data, config)
  } catch (error) {
    console.log(error)
  }
}

function loadWxSdk() {
  return new Promise((resolve, reject) => {
    wxJsSdkScript = document.createElement('script')
    wxJsSdkScript.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js'
    document.body.appendChild(wxJsSdkScript)
    wxJsSdkScript.onload = () => {
      resolve()
    }
    wxJsSdkScript.onerror = () => {
      reject()
    }
  })
}

function configWxSdk(data, config) {
  return new Promise((resolve, reject) => {
    window.wx.ready(() => {
      console.log('wx ready')
      resolve(window.wx)
    })
    // eslint-disable-next-line
    window.wx.error((error) => {
      console.log('error: ', error)
      reject(error)
    })
    window.wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: config.jsApiList || [],
      openTagList: config.openTagList || []
    })
    // eslint-disable-next-line
  })
}
