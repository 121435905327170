import request from '@/apps/activity/utils/request'

export function sendVerifyCodeAPI(params) {
  return request({
    url: '/api/user/sendVerifyCode/v1',
    method: 'post',
    params
  })
}

export function loginAPI(params) {
  return request({
    url: '/api/user/login/v1',
    method: 'post',
    params
  })
}

// 验证token用
export function checkTokenAPI() {
  return request({
    url: '/api/meditation/user/getUserInfo/v1',
    method: 'post'
  })
}

// 获取图片上传权限
export function getAuth() {
  return request({
    url: '/api/meditation/user/getAuth',
    method: 'get'
  })
}

// 获取wx-js-sdk配置
export function wxJsSdkSignatureAPI(params) {
  return request({
    url: '/api/course/OfficialAccount/v1',
    method: 'get',
    params
  })
}

// 公众号授权
export function getPublicOpenIdAPI(params) {
  return request({
    url: 'api/user/getPublicOpenId',
    method: 'post',
    params
  })
}
