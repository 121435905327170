import Vue from 'vue'

import { Button, Popup, Form, Field, Checkbox, Image, PullRefresh, Uploader, RadioGroup, Radio, Overlay } from 'vant'

Vue.use(Button)
Vue.use(Popup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Checkbox)
Vue.use(Image)
Vue.use(PullRefresh)
Vue.use(Uploader)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Overlay)
