<template>
  <div class="page">
    <div class="nav">
      <div @click="$router.back()" class="back">
        <img src="@/apps/activity/assets/img/back.png" />
      </div>
      <div class="title">{{ info.title }}</div>
    </div>
    <div class="form-title">填写报名信息</div>
    <div v-if="info.formInformation" class="form">
      <van-form @submit="onSubmit">
        <van-field v-if="info.formInformation.name" class="form-field" v-model="form.name" name="name" label="姓名" maxlength="8" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field v-if="info.formInformation.wechat" class="form-field" v-model="form.wechat" name="wechat" label="微信号" maxlength="25" :rules="[{ required: true, message: '请填写微信号' }]" />
        <van-field
          v-if="info.formInformation.mobile"
          class="form-field"
          v-model="form.mobile"
          name="mobile"
          label="联系电话"
          :rules="[
            {
              validator(val) {
                return /^1\d{10}$/.test(val)
              },
              message: '请输入正确的联系电话',
              required: true
            }
          ]"
        />
        <van-field
          v-if="info.formInformation.idNumber"
          class="form-field"
          v-model="form.idNumber"
          name="idNumber"
          label="证件号码"
          :rules="[
            {
              validator(val) {
                return /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/.test(val)
                // return true
              },
              message: '请输入正确的证件号码',
              required: true
            }
          ]"
        />
        <van-field
          v-if="info.formInformation.qualificationCertificate"
          class="form-field"
          name="qualificationCertificate"
          label="资质证件"
          :rules="[
            {
              validator(val) {
                if (!val || !val.length) {
                  return false
                }
              },
              message: '请上传资质证件',
              required: true
            }
          ]"
        >
          <template #input>
            <van-uploader v-model="form.qualificationCertificate" max-count="5" :after-read="uploadQCImg" :before-delete="delQCImg" :max-size="5 * 1024 * 1024" @oversize="onOversizeQCImg" :upload-icon="upImageIcon">
              <template #preview-cover="{ url, type }">
                <img @click.stop="videoPreview(url, type)" class="preview-cover" :src="url" />
              </template>
            </van-uploader>
          </template>
        </van-field>
        <van-field
          v-if="info.formInformation.work"
          class="form-field"
          name="work"
          label="作品"
          :rules="[
            {
              validator(val) {
                if (!val || !val.length) {
                  return false
                }
              },
              message: '请上传作品',
              required: true
            }
          ]"
        >
          <template #input>
            <van-uploader v-model="form.work" accept="video/*,image/*" max-count="5" :after-read="uploadWork" :before-delete="delWork" :max-size="isOverSizeWork" @oversize="onOversizeWork" :upload-icon="upImageVideoIcon">
              <template #preview-cover="{ url, type }">
                <video v-if="new RegExp('^video/.*$').test(type)" class="preview-cover" :src="url" @click="videoPreview(url, type)"></video>
                <img v-else @click.stop="videoPreview(url, type)" class="preview-cover" :src="url" />
              </template>
            </van-uploader>
          </template>
        </van-field>
        <van-field
          v-if="ticket.price && !isWeChat"
          class="form-field"
          name="payType"
          label="支付方式"
          :rules="[
            {
              message: '请选择支付方式',
              required: true
            }
          ]"
        >
          <template #input>
            <van-radio-group class="my-radio-group" v-model="form.payType">
              <van-radio class="my-radio" label-position="left" :name="3">
                <div class="field-radio-label">
                  <img class="label-icon" src="@/apps/activity/assets/img/wechat_pay.png" />
                  <div class="label-text">微信支付</div>
                </div>
                <template #icon="props">
                  <img class="field-raido-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
              </van-radio>
              <van-radio class="my-radio" label-position="left" :name="4">
                <div class="field-radio-label">
                  <img class="label-icon" src="@/apps/activity/assets/img/alipay.png" />
                  <div class="label-text">支付宝支付</div>
                </div>
                <template #icon="props">
                  <img class="field-raido-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <div v-if="info.notes" class="notes">
          <div class="notes-title">报名须知：</div>
          <div class="notes-content">{{ info.notes }}</div>
        </div>
        <van-button :disabled="loading" class="btn" round block type="info" native-type="submit">提交</van-button>
        <van-button @click="onReset" class="btn btn-reset" round plain type="info">重置</van-button>
      </van-form>
    </div>
    <van-overlay :show="showPreview" @click="showPreview = false">
      <video v-if="new RegExp('^video/.*$').test(showPreviewType)" :src="showPreviewUrl" controls class="preview-box"></video>
      <img v-else class="preview-box" :src="showPreviewUrl" />
    </van-overlay>
    <van-overlay :show="showCheck" @click="onCloseCheck">
      <div class="check-wrapper" @click.stop>
        <div class="title">请确认支付是否已完成?</div>
        <div class="secs">({{ this.secs }})</div>
        <van-button @click="checkPay" :disabled="isChecking" class="check">{{ isChecking ? '查询中' : '已完成支付' }}</van-button>
        <div @click="onRePay" class="repay">未跳转支付页？手动打开</div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { cloneDeep } from 'lodash-es'
  import COS from 'cos-js-sdk-v5'
  import activeIcon from '@/apps/activity/assets/img/checked.png'
  import inactiveIcon from '@/apps/activity/assets/img/check.png'
  import upImageIcon from '@/apps/activity/assets/img/up_img.png'
  import upImageVideoIcon from '@/apps/activity/assets/img/up_vi.png'
  import { getActivityInfoAPI, activityApplyAPI, getPayResultAPI } from '@/apps/activity/api/index'
  import { getAuth } from '@/apps/activity/api/user'
  import { isWeChat } from '@/utils/index'
  import { getWxJsSdk } from '@/apps/activity/utils/wx'

  export default {
    data() {
      return {
        loading: false,
        showCheck: false,
        secs: 60,
        isChecking: false,
        activeIcon,
        inactiveIcon,
        upImageIcon,
        upImageVideoIcon,
        info: {},
        payInfo: {},
        form: JSON.parse(localStorage.getItem('form')) || {},
        showPreview: false,
        showPreviewUrl: '',
        showPreviewType: '',
        isWeChat: false
      }
    },
    computed: {
      ticket() {
        if (this.info.ticketType) {
          return this.info.ticketType.find((item) => item.id === this.$route.query.ticketTypeId)
        } else {
          return null
        }
      }
    },
    mounted() {
      if (isWeChat()) {
        this.isWeChat = true
        this.initWxJsSdk()
      }
      this.getInfo()
      // 如果存在订单信息，微信支付成功后会在新标签打开此页面，如果有订单信息则显示
      // 支付成功返回本页面时
      let payInfo = localStorage.getItem('payInfo')
      if (payInfo) {
        this.showCheck = true
        this.payInfo = JSON.parse(payInfo)
      }
    },
    methods: {
      initWxJsSdk() {
        getWxJsSdk({
          jsApiList: ['chooseWXPay']
        }).then((res) => {
          if (res) {
            console.log(res)
            this.wx = res
            console.log(this.$store.state.openid)
          }
        })
      },
      getInfo() {
        // 8HhxF77M
        getActivityInfoAPI({ id: this.$route.query.id })
          .then((res) => {
            document.title = res.data.title
            this.info = res.data
          })
          .catch(() => {})
      },
      getFileUrl(file) {
        return URL.createObjectURL(file)
      },
      videoPreview(url, type) {
        this.showPreview = true
        this.showPreviewUrl = url
        this.showPreviewType = type
      },
      // 创建COS实例  获取签名
      async genCOS() {
        try {
          const res = await getAuth()
          const data = res.data
          return new COS({
            // 必选参数
            getAuthorization: (options, callback) => {
              const obj = {
                TmpSecretId: data.tmpSecretId,
                TmpSecretKey: data.tmpSecretKey,
                XCosSecurityToken: data.sessionToken,
                ExpiredTime: data.expiredTime // 时间戳，单位秒，如：1580000900
              }
              callback(obj)
            }
          })
        } catch (error) {
          return false
        }
      },
      async uploadQCImg(file, detail) {
        try {
          file = file.file
          const cos = await this.genCOS()
          if (!cos) return
          let index = file.name.lastIndexOf('.')
          let ext = file.name.slice(index)
          let randomWord = Math.random().toString(32).slice(2)
          let key = 'app_admin/activity/user/' + randomWord + '_' + new Date().getTime() + ext
          cos.putObject(
            {
              Bucket: 'yoga-zh-1304465545',
              Region: 'ap-shanghai',
              Key: key,
              StorageClass: 'STANDARD',
              Body: file // 上传文件对象
            },
            (err, data) => {
              if (err) {
                return
              }
              if (data && data.Location) {
                this.form.qualificationCertificate[detail.index].key = key
                this.$set(this.form.qualificationCertificate[detail.index], 'url', `https://${data.Location}`)
                this.$set(this.form.qualificationCertificate[detail.index], 'type', file.type)
              }
            }
          )
        } catch (error) {
          console.log(error)
        }
      },
      // eslint-disable-next-line
      async delQCImg(file) {
        /* try {
          const cos = await this.genCOS()
          if (!cos) return
          let key = file.key
          cos.deleteObject(
            {
              Bucket: 'yoga-zh-1304465545',
              Region: 'ap-shanghai',
              Key: key
            },
            (err) => {
              if (err) {
                console.log(err)
                return
              }
            }
          )
        } catch (error) {
          console.log(error)
        } */
      },
      onOversizeQCImg() {
        Toast('图片大小不能超过5m')
      },
      async uploadWork(file, detail) {
        try {
          file = file.file
          const cos = await this.genCOS()
          if (!cos) return
          let index = file.name.lastIndexOf('.')
          let ext = file.name.slice(index)
          let randomWord = Math.random().toString(32).slice(2)
          let key = 'app_admin/activity/user/' + randomWord + '_' + new Date().getTime() + ext
          cos.putObject(
            {
              Bucket: 'yoga-zh-1304465545',
              Region: 'ap-shanghai',
              Key: key,
              StorageClass: 'STANDARD',
              Body: file // 上传文件对象
            },
            (err, data) => {
              if (err) {
                return
              }
              if (data && data.Location) {
                this.form.work[detail.index].key = key
                this.$set(this.form.work[detail.index], 'url', `https://${data.Location}`)
                this.$set(this.form.work[detail.index], 'type', file.type)
              }
            }
          )
        } catch (error) {
          console.log(error)
        }
      },
      // eslint-disable-next-line
      async delWork(file) {
        /* try {
          const cos = await this.genCOS()
          if (!cos) return
          let key = file.key
          cos.deleteObject(
            {
              Bucket: 'yoga-zh-1304465545',
              Region: 'ap-shanghai',
              Key: key
            },
            (err) => {
              if (err) {
                console.log(err)
                return
              }
            }
          )
        } catch (error) {
          console.log(error)
        } */
      },
      isOverSizeWork(file) {
        const maxSize = new RegExp('^video/.*$').test(file.type) ? 100 * 1024 * 1024 : 5 * 1024 * 1024
        return file.size >= maxSize
      },
      onOversizeWork(file) {
        file = file.file
        const msg = new RegExp('^video/.*$').test(file.type) ? '视频大小不能超过100m' : '图片大小不能超过5m'
        Toast(msg)
      },
      onReset() {
        this.form = {}
        localStorage.removeItem('form')
      },
      onSubmit() {
        let formInformation = this.info.formInformation
        let data = cloneDeep(this.form)
        data.activityId = this.info.id
        if (formInformation.qualificationCertificate) {
          data.qualificationCertificate = data.qualificationCertificate.map((item) => item.url)
        } else {
          data.qualificationCertificate = null
        }
        if (formInformation.work) {
          data.work = data.work.map((item) => item.url)
        } else {
          data.work = null
        }
        this.loading = true
        // 暂存表单
        let form = cloneDeep(this.form)
        if (formInformation.qualificationCertificate) {
          form.qualificationCertificate = form.qualificationCertificate.map((item) => {
            return {
              key: item.key,
              type: item.type,
              url: item.url
            }
          })
        }
        if (formInformation.work) {
          form.work = form.work.map((item) => {
            return {
              key: item.key,
              type: item.type,
              url: item.url
            }
          })
        }
        localStorage.setItem('form', JSON.stringify(form))
        // 微信端而且不是免费活动单独处理，调用微信支付
        if (isWeChat() && this.ticket.price) {
          data.openid = this.$store.state.openid
          data.payType = 5
          data.ticketTypeId = this.$route.query.ticketTypeId
          activityApplyAPI(data)
            .then((res) => {
              const result = res.data.result
              this.loading = false
              this.wx.chooseWXPay({
                timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: result.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                paySign: result.sign, // 支付签名
                success: () => {
                  // 支付成功后的回调函数
                  localStorage.removeItem('form')
                  Toast({
                    message: '支付成功',
                    onClose: () => {
                      this.$router.replace(`/?id=${this.$route.query.id}`)
                    }
                  })
                }
              })
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          data.ticketTypeId = this.$route.query.ticketTypeId
          // 浏览器端付费，浏览器端免费，微信端免费活动
          // eslint-disable-next-line
          activityApplyAPI(data)
            .then((res) => {
              this.loading = false
              if (this.ticket.price) {
                // window.open(res.data.result)
                location.href = res.data.result
                this.payInfo = res.data
                res.data.createTime = Date.now()
                localStorage.setItem('payInfo', JSON.stringify(res.data))
                // 跳转支付后应该是自动刷新了，开发时由于本地非跟路径访问导致了丢失，但是线上会显示刷新后的此页面
                // 有些浏览器不会刷新，而是保留在此页面，加这个确保出现弹窗
                setTimeout(() => {
                  this.showCheck = true
                }, 1000)
              } else {
                // 免费活动报名成功
                localStorage.removeItem('form')
                Toast({
                  message: '报名成功',
                  onClose: () => {
                    this.$router.replace(`/?id=${this.$route.query.id}`)
                  }
                })
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      },
      /**
       * 需要用户手动确认后发起轮训
       */
      checkPay() {
        clearInterval(this.timer)
        this.secs = 60
        this.isChecking = true
        this.timer = setInterval(() => {
          if (this.secs === 0) {
            this.isChecking = false
            clearInterval(this.timer)
            localStorage.removeItem('payInfo') // 60s未完成支付则下次不会弹出弹框了，视为放弃本订单
            return
          }
          this.secs = this.secs - 1
          getPayResultAPI({ outTradeNo: this.payInfo.orderSn }).then((res) => {
            if (res.data) {
              Toast({
                message: '支付成功',
                onClose: () => {
                  this.$router.replace(`/?id=${this.$route.query.id}`)
                }
              })
              localStorage.removeItem('payInfo')
              localStorage.removeItem('form')
              this.showCheck = false
              this.isChecking = false
              clearInterval(this.timer)
            }
          })
        }, 1000)
      },
      /**
       * 1. 未支付，清除定时器后继续去支付
       * 2. 已支付，弹窗已关闭
       * 3. 已支付，回调延迟，但是已经跳转了
       * - 未点击查询，下次查询即可
       * - 查询未确认定时器即被清除，同下次进入后重新查询
       * - 查询已确认，本地订单信息被清除，回到首页，查询到已经报名该活动
       *
       */
      onRePay() {
        clearInterval(this.timer)
        this.isChecking = false
        this.secs = 60
        // window.open(this.payInfo.result)
        location.href = this.payInfo.result
      },
      // 查询过程中点击了关闭，如果未确认付款，则视为取消订单
      onCloseCheck() {
        clearInterval(this.timer)
        this.showCheck = false
        localStorage.removeItem('payInfo')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100vh;
    background: #f8f8f9;
    padding-bottom: 150px;
  }
  .nav {
    position: relative;
    height: 80px;
    background: linear-gradient(180deg, #ffe2dc 0%, #f8f8f9 100%);
    .back {
      position: absolute;
      width: 30%;
      height: 80px;
      line-height: 80px;
      box-sizing: border-box;
      padding-left: 23px;
      img {
        width: 7px;
        height: 13px;
      }
    }
    .title {
      width: 60%;
      height: 80px;
      line-height: 80px;
      margin: 0 auto;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      color: #333;
    }
  }
  .form-title {
    padding: 20px 30px;
    font-size: 30px;
    color: #333333;
  }
  .form {
    padding: 0 30px;
    .form-field {
      border-radius: 23px;
      margin-bottom: 16px;
      font-size: 16px;
      .preview-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .my-radio-group {
        width: 100%;
      }
      .my-radio {
        margin-bottom: 20px;
        justify-content: space-between;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .field-radio-label {
        display: flex;
        align-items: center;
        .label-icon {
          width: 22px;
          height: 19px;
          margin-right: 9px;
        }
        .label-text {
          font-size: 15px;
          color: #333333;
          font-weight: 300;
        }
      }
      .field-raido-icon {
        width: 18px;
        height: 18px;
      }
    }
    .notes {
      padding: 0 10px;
      .notes-title {
        margin-bottom: 10px;
        font-size: 18px;
        color: #333333;
      }
      .notes-content {
        font-size: 13px;
        color: #5a5a5a;
      }
    }
    .btn {
      position: fixed;
      left: 15px;
      bottom: 75px;
      width: 345px;
      height: 45px;
      background: linear-gradient(141deg, #faa18c 0%, #f08870 100%);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 27px;
      border: none;
    }
    .btn-reset {
      bottom: 20px;
      background: #fff;
      color: #333;
    }
  }
  .preview-box {
    position: absolute;
    width: 90%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .check-wrapper {
    position: absolute;
    width: 300px;
    height: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 42px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 30px;
    text-align: center;
    .title {
      margin-bottom: 12px;
      font-size: 18px;
      color: #333333;
      font-weight: 600;
    }
    .secs {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 14px;
      color: #ff0000;
    }
    .check {
      width: 244px;
      height: 43px;
      margin-bottom: 17px;
      color: #fff;
      background: #8076f1;
      border-radius: 22px;
    }
    .repay {
      font-size: 13px;
      font-weight: 500;
      color: #8076f1;
    }
  }
  ::v-deep .van-icon__image {
    width: 100%;
    height: 100%;
  }
</style>
