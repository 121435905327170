import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setTitle } from '@/utils/index'
import dayjs from 'dayjs'

import './vConsole'
import 'normalize.css'
import '@/apps/activity/assets/styles/index.scss'
import '@/apps/activity/components/vant'

Vue.config.productionTip = false
Vue.prototype.$setTitle = setTitle
Vue.prototype.$dayjs = dayjs
new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
