<template>
  <div ref="wrap" class="wrap">
    <div class="brand">
      <img class="logo" src="@/apps/activity/assets/img/logo.png" alt="" />
      <div class="title">YUREN瑜伽 APP</div>
    </div>
    <div v-if="!isWeChat" @click="onOpenCilent" class="btn">立即进入</div>
    <div v-else class="btn">
      <wx-open-launch-app-wrap>
        <div>立即进入</div>
      </wx-open-launch-app-wrap>
    </div>
  </div>
</template>

<script>
  import { isWeChat, isiOS } from '@/utils/index'
  import WxOpenLaunchAppWrap from './WxOpenLaunchAppWrap.vue'
  import { debounce } from 'throttle-debounce'
  export default {
    components: {
      WxOpenLaunchAppWrap
    },
    data() {
      return {
        isOpening: false,
        isWeChat: false,
        handleTouchMove: null
      }
    },
    mounted() {
      this.isWeChat = isWeChat()
      this.handleTouchMove = debounce(
        150,
        () => {
          this.$refs.wrap.classList.add('wrap-hidden')
        },
        {
          atBegin: true
        }
      )
      window.addEventListener('touchmove', this.handleTouchMove)
      window.addEventListener('touchend', this.handleTouchEnd)
    },
    beforeDestroy() {
      window.removeEventListener('touchmove', this.handleTouchMove)
      window.removeEventListener('touchend', this.handleTouchEnd)
    },
    methods: {
      onOpenCilent() {
        if (this.isOpening) {
          return
        }
        this.isOpening = true
        this.timer = setTimeout(() => {
          this.isOpening = false
          if (isiOS()) {
            location.href = 'https://apps.apple.com/cn/app/yuren%E7%91%9C%E4%BC%BD-%E6%9B%B4%E4%B8%93%E4%B8%9A%E7%9A%84%E7%91%9C%E4%BC%BD%E7%BB%83%E4%B9%A0app/id1551747869'
          } else {
            location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.yurenyoga.com&fromcase=40003'
          }
        }, 3000)
        if (isiOS()) {
          location.href = 'iosyurenyogaJZLYuJia://'
        } else {
          location.href = 'scheme://sms/courseDetail'
        }
      },
      handleTouchEnd() {
        setTimeout(() => {
          if (this.$refs.wrap) {
            this.$refs.wrap.classList.remove('wrap-hidden')
          }
        }, 500)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wrap {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 0 10px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.9;
    z-index: 100;
    transition: all 0.5s;
    .brand {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }
      .title {
        font-size: 16px;
        margin-left: 8px;
      }
    }
    .btn {
      width: 80px;
      height: 35px;
      line-height: 35px;
      cursor: pointer;
      border-radius: 18px;
      text-align: center;
      font-size: 14px;
      background-color: #8a89f4;
      color: #fff;
      overflow: hidden;
    }
  }
  .wrap-hidden {
    top: -60px;
    opacity: 0.3;
  }
</style>
