<template>
  <div class="page">
    <div @click="$router.back()" class="back">
      <img src="@/apps/activity/assets/img/back_icon_white.png" />
    </div>
    <div class="title">入场券</div>
    <div class="info">
      <div class="info-in">
        <div class="info-in-top">
          <div class="info-in-top-dot"></div>
          <div class="info-in-top-bar"></div>
        </div>
        <div v-if="applyInfo" class="info-qrcode">
          <vue-qr :size="200" :text="applyInfo.id" :margin="10"></vue-qr>
          <div v-if="applyInfo.writeOff" class="info-qrcode-mask">二维码已失效</div>
        </div>
        <div class="info-tip">你可以截图保存凭证哦~</div>
        <div class="info-line"></div>
        <div class="info-field">票种：{{ applyInfo.title }}</div>
        <div class="info-field">活动：{{ applyInfo.ticketTypeName }}</div>
        <div class="info-field">活动时间：{{ $dayjs(applyInfo.startTime).format('MM-DD HH:mm') }}/{{ $dayjs(applyInfo.endTime).format('MM-DD HH:mm') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueQr from 'vue-qr'
  import { mapState } from 'vuex'
  export default {
    components: {
      VueQr
    },
    computed: {
      ...mapState({
        applyInfo: (state) => state.applyInfo
      })
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 42px;
    position: relative;
    background-image: url(https://yoga-zh-1304465545.cos.ap-shanghai.myqcloud.com/app_admin/assets/code_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    .back {
      position: absolute;
      width: 30%;
      height: 80px;
      top: 0;
      left: 0;
      padding-top: 23px;
      box-sizing: border-box;
      padding-left: 23px;
      img {
        width: 7px;
        height: 13px;
      }
    }
    .title {
      text-align: center;
      font-size: 26px;
      color: #ffffff;
    }
    .info {
      margin: 30px auto 0;
      width: 338px;
      height: 560px;
      background: rgba($color: #fff, $alpha: 0.13);
      border-radius: 16px;
      padding-top: 15px;
      .info-in {
        width: 314px;
        height: 530px;
        background: #ffffff;
        border-radius: 16px;
        margin: auto;
        text-align: center;
        .info-in-top {
          display: flex;
          padding-top: 23px;
          justify-content: center;
          align-items: center;
          .info-in-top-dot {
            width: 12px;
            height: 12px;
            margin-right: 7px;
            background: #8478f3;
            border-radius: 50%;
            opacity: 0.66;
          }
          .info-in-top-bar {
            width: 58px;
            height: 10px;
            background: #8478f3;
            opacity: 0.66;
            border-radius: 5px;
          }
        }
        .info-qrcode {
          display: inline-block;
          margin-top: 25px;
          position: relative;
          .info-qrcode-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.6);
            backdrop-filter: saturate(180%) blur(8px);
            color: #fff;
            font-size: 16px;
            line-height: 180px;
          }
        }

        .info-tip {
          margin-top: 18px;
          text-align: center;
          font-size: 14px;
          font-weight: 300;
          color: #000000;
        }
        .info-line {
          height: 1px;
          background: #f6f6f6;
          margin: 35px 0 16px;
        }
        .info-field {
          text-align: left;
          padding: 8px 30px;
          font-size: 14px;
          color: #101010;
        }
      }
    }
  }
</style>
