<template>
  <div>
    <div class="top-bg"></div>
    <img class="logo" src="@/apps/activity/assets/img/logo_big.png" alt="" />
    <div class="title">YUREN瑜伽</div>
    <van-form class="form" @submit="onSubmit">
      <div class="form-label">手机号码：</div>
      <van-field
        class="form-field"
        v-model="form.mobile"
        name="mobile"
        :rules="[
          {
            validator(val) {
              return /^1\d{10}$/.test(val)
            },
            message: '请输入正确的手机号码',
            required: true
          }
        ]"
      />
      <div class="form-tip-title">温馨提示：</div>
      <div class="form-tip-text">为了确保您是本人操作，请输入报名手机号进行验证，验证成功后方可检验二维码</div>
      <van-button class="form-btn" round block type="info" native-type="submit">确认</van-button>
    </van-form>
  </div>
</template>

<script>
  import { writeOffCodeAPI } from '@/apps/activity/api/index.js'
  export default {
    data() {
      return {
        form: {
          mobile: ''
        }
      }
    },
    mounted() {
      // 进入此页面时清空数据
      this.form.mobile = ''
      this.$store.commit('CLEAR_APPLY_INFO')
    },
    methods: {
      onSubmit() {
        writeOffCodeAPI({
          id: this.$route.query.id,
          mobile: this.form.mobile
        }).then((res) => {
          if (res.code === 1000) {
            this.$store.commit('UPDATE_APPLY_INFO', {
              ...res.data,
              id: this.$route.query.id
            })
            this.$router.push('/writeOff/code')
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-bg {
    height: 88px;
    background: linear-gradient(180deg, #d4cbfc 0%, #ffffff 100%);
  }
  .logo {
    width: 94px;
    height: 94px;
    display: block;
    margin: 65px auto 0;
  }
  .title {
    margin-top: 18px;
    text-align: center;
    font-size: 22px;
  }
  .form {
    margin-top: 60px;
    padding: 0px 42px;
    .form-label {
      font-size: 16px;
    }
    .form-field {
      width: 290px;
      height: 53px;
      border-radius: 16px;
      border: 1px solid #8176f1;
      margin-top: 18px;
      overflow: visible;
      font-size: 24px;
      ::v-deep .van-field__error-message {
        margin-top: 20px;
      }
    }
    .form-tip-title {
      margin-top: 36px;
      font-size: 14px;
    }
    .form-tip-text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 300;
      color: #848484;
    }
    .form-btn {
      margin-top: 39px;
      width: 290px;
      height: 53px;
      background: linear-gradient(130deg, #9c8efb 0%, #ac98ff 100%);
      border: none;
      border-radius: 28px;
    }
  }
</style>
