export function setTitle(title) {
  document.title = title
}

export function isWeChat() {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  let ua = window.navigator.userAgent.toLowerCase()
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  } else {
    return false
  }
}

export function isiOS() {
  let ua = window.navigator.userAgent.toLowerCase()
  return ua && /iphone|ipad|ipod|ios/.test(ua)
}

export function isAndroid() {
  let ua = window.navigator.userAgent.toLowerCase()
  return ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
}
