import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FormView from '../views/FormView.vue'
import WriteOffIndex from '../views/WriteOffView/index.vue'
import WriteOffCode from '../views/WriteOffView/code.vue'
import { isWeChat } from '@/utils/index'
import { getPublicOpenIdAPI } from '@/apps/activity/api/user'
import store from '@/apps/activity/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/form',
    name: 'form',
    component: FormView
  },
  {
    path: '/wx-auth-fail',
    component: () => import(/* webpackChunkName: "wx-auth-fail" */ '../views/WxAuthFail.vue')
  },
  {
    path: '/writeOff',
    component: WriteOffIndex
  },
  {
    path: '/writeOff/code',
    component: WriteOffCode
  },
  {
    path: '/meditationWriteOff',
    component: () => import('../views/MeditationWriteOffView/index.vue')
  },
  {
    path: '/meditationWriteOff/code',
    component: () => import('../views/MeditationWriteOffView/code.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes
})

if (isWeChat()) {
  router.beforeEach((to, from, next) => {
    if (to.path === '/' && !store.state.isWeChatAuth) {
      if (to.query.code) {
        getPublicOpenIdAPI({ code: to.query.code }).then((res) => {
          if (res.data.openid) {
            store.commit('UPDATE_IS_WECAHT_AUTH', true)
            store.commit('UPDATE_OPENID', res.data.openid)
            next()
          } else {
            next('/wx-auth-fail')
          }
        })
      } else {
        const wxCallbackHost = process.env.NODE_ENV !== 'production' ? 'dev.activity.yogayuren.com' : process.env.VUE_APP_WX_CALLBACK_HOST
        location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx309bdf56558b9012&redirect_uri=https://${wxCallbackHost}/?id=${to.query.id}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      }
      // 进入首页时如果未授权
    } else {
      next()
    }
  })
}

export default router
