<template>
  <div class="wx-open-launch-weapp-block">
    <div class="slot-wrap">
      <slot></slot>
    </div>
    <div class="open-tag-wrap">
      <wx-open-launch-app class="launch-btn" id="launch-btn" appid="wx309bdf56558b9012">
        <script type="text/wxtag-template">
          <!-- 这里是为了撑开元素，让其可以被点击，不添加template会报错，添加了template不添加内容也会报错 -->
          <div style="padding: 10000px">
          </div>
        </script>
      </wx-open-launch-app>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { isWeChat, isiOS } from '@/utils/index'
  import { getWxJsSdk } from '@/apps/activity/utils/wx.js'
  Vue.config.ignoredElements = ['wx-open-launch-app']
  export default {
    data() {
      return {
        isWeChat: false
      }
    },
    mounted() {
      this.isWeChat = isWeChat()
      if (this.isWeChat) {
        this.init()
      }
    },
    methods: {
      async init() {
        await getWxJsSdk({ openTagList: ['wx-open-launch-app'] })
        const btn = document.getElementById('launch-btn')
        btn.addEventListener('launch', function () {
          console.log('success')
        })
        btn.addEventListener('error', function (e) {
          console.log('fail', e.detail)
          if (isiOS()) {
            location.href = 'https://apps.apple.com/cn/app/yuren%E7%91%9C%E4%BC%BD-%E6%9B%B4%E4%B8%93%E4%B8%9A%E7%9A%84%E7%91%9C%E4%BC%BD%E7%BB%83%E4%B9%A0app/id1551747869'
          } else {
            location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.yurenyoga.com&fromcase=40003'
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .wx-open-launch-weapp-block {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    .slot-wrap {
      width: 100%;
      height: 100%;
      text-align: center;
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .open-tag-wrap {
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
    }
    .launch-btn {
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
    }
  }
</style>
