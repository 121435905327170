<template>
  <div id="app">
    <router-view />
    <login-popup></login-popup>
  </div>
</template>

<script>
  import LoginPopup from '@/apps/activity/components/LoginPopup.vue'
  import { checkTokenAPI } from '@/apps/activity/api/user'
  export default {
    components: {
      LoginPopup
    },
    mounted() {
      // 原来构建的多页应用此项目不是根目录，所以需要跳转一下，现在不用了
      /* if (this.$route.path === '/app-activity') {
        this.$router.push({
          path: '/',
          query: this.$route.query
        })
      } */
      // 验证token是否过期，只有首页进行验证
      if (this.$store.state.userInfo.token && this.$store.state.userInfo.id && this.$route.path === '/') {
        checkTokenAPI()
      }

      // 加载聊天系统 https://docs.easemob.com/cs/300visitoraccess/web-widget
      const chat = document.createElement('script')
      chat.src = '//104199.kefu.easemob.com/webim/easemob.js?configId=a6a8a4a6-838d-445a-b86f-6feb504054b0'
      chat.onload = () => {
        window.easemobim.config = {
          hide: true,
          autoConnect: false
        }
      }
      document.body.appendChild(chat)
    }
  }
</script>

<style lang="scss" scoped></style>
