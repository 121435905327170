import request from '@/apps/activity/utils/request'

// 活动详情
export function getActivityInfoAPI(params) {
  return request({
    url: '/api/activity/info/v3',
    method: 'get',
    params
  })
}

// 活动报名
export function activityApplyAPI(data) {
  return request({
    url: '/api/activity/apply/v2',
    method: 'post',
    data
  })
}

// 获取支付结果
export function getPayResultAPI(params) {
  return request({
    url: '/api/activity/apply/getPayResult/v2',
    method: 'get',
    params
  })
}

// 报名详情
export function applyInfoAPI(params) {
  return request({
    url: '/api/activity/applyInfo/v3',
    method: 'get',
    params
  })
}

// 查询核销码信息
export function writeOffCodeAPI(params) {
  return request({
    url: '/api/activity/writeOffCode/v3',
    method: 'get',
    params
  })
}

// 查询冥想小程序核销码信息
export function meditationWriteOffCodeAPI(params) {
  return request({
    url: '/api/meditation/activity/writeOffCode/v3',
    method: 'get',
    params
  })
}
