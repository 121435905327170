<template>
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <div class="page">
      <open-app></open-app>
      <van-image class="cover" :src="info.cover" fit="cover" />
      <div class="info">
        <div class="info-card">
          <div class="title">{{ info.title }}</div>
          <div class="item">
            <img src="@/apps/activity/assets/img/clock.png" class="icon" />
            <div class="text">{{ $dayjs(info.startTimestamp).format('MM.DD-HH:ss') }} 至 {{ $dayjs(info.endTimestamp).format('MM.DD-HH:ss') }}</div>
          </div>
          <div class="item">
            <img src="@/apps/activity/assets/img/address.png" class="icon" />
            <div class="text">{{ info.area }}</div>
          </div>
          <div v-if="info.amount" class="item">
            <img src="@/apps/activity/assets/img/money.png" class="icon" />
            <div class="text money">¥{{ info.amount }}</div>
          </div>
          <div class="item">
            <img src="@/apps/activity/assets/img/users.png" class="icon" />
            <div class="text">{{ info.applicantsVirtualNumber === 0 ? info.applicantsNumber : info.applicantsVirtualNumber }}人已参加</div>
          </div>
        </div>
        <div class="info-content">
          <van-image v-for="(item, index) in info.content" :key="index" class="content" :src="item" fit="cover" />
        </div>
      </div>
      <div class="footer">
        <div class="footer-banner" v-if="info.apply && info.writeOff">
          <div class="banner-left">
            <img class="left-icon" src="@/apps/activity/assets/img/apply_icon.png" alt="" />
            <div class="left-text">你已报名此活动</div>
          </div>
          <div @click="onShowResult" class="banner-btn">查看详情</div>
        </div>
        <div class="footer-tool">
          <img class="tool-img" @click="onShare" src="@/apps/activity/assets/img/share.png" alt="" />
          <div class="tool-text">分享</div>
        </div>
        <div class="footer-tool">
          <img class="tool-img" @click="onService" src="@/apps/activity/assets/img/service.png" alt="" />
          <div class="tool-text">客服</div>
        </div>
        <div v-if="!info.apply" @click="onApply" class="btn">
          <div class="text">立即报名</div>
        </div>
        <div v-else class="btn-applied">已报名</div>
      </div>
      <!-- <div v-if="showOpenOut" class="open-out">
        <img src="@/apps/activity/assets/img/open_out.png" alt="" />
      </div> -->
      <van-popup v-model="showTicket" class="ticket-popup" round closeable safe-area-inset-bottom position="bottom">
        <div class="title">选择票种</div>
        <div v-if="isBeforeSellStartTime" class="tip">该活动将于 {{ info.sellStartTime }} 开始售票</div>
        <div v-if="curTicket" @touchmove.stop class="list">
          <div v-for="ticket in info.ticketType" :key="ticket.id" @click="onChooseTicket(ticket)" class="ticket" :class="{ active: curTicket.id === ticket.id }">
            <div class="name">{{ ticket.name }}</div>
            <div>
              <div class="price">
                <span class="price-title">早鸟</span>
                <span class="price-num">¥{{ ticket.price }}</span>
              </div>
              <div class="oprice">
                <span class="oprice-title">晚鸟</span>
                <span class="oprice-num">¥{{ ticket.originPrice }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tfooter">
          <div class="price">
            <span class="price-text">合计：</span>
            <span v-if="curTicket" class="price-num">¥{{ curTicket.price }}</span>
          </div>
          <!-- 不在售票时间范围内或活动已结束 -->
          <div v-if="isBeforeSellStartTime || isAfterSellEndTime || info.status === 3" class="next next-gray">下一步</div>
          <div v-else @click="onNext" class="next">下一步</div>
        </div>
      </van-popup>
      <van-popup v-model="showResult" round closeable safe-area-inset-bottom>
        <div class="result-popup">
          <div class="writeoff" v-if="applyInfo.writeOff">已核销</div>
          <div class="writeoff" v-else>未核销</div>
          <div class="ctime">报名时间：{{ $dayjs(applyInfo.createTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          <div class="line"></div>
          <div class="tname">票种：{{ applyInfo.ticketTypeName }}</div>
          <div v-if="applyInfo.id" @click="onPreivewTicketQrcode" class="tqrcode">
            <vue-qr :size="180" :text="applyInfo.id" :margin="10" :callback="onTicketQrcode"></vue-qr>
            <div v-if="applyInfo.writeOff" class="tqrcode-mask">二维码已失效</div>
          </div>
          <div class="wtime" v-if="applyInfo.writeOff">核销时间：{{ $dayjs(applyInfo.writeOffTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          <div class="ticket-tips">温馨提示：核销码为唯一核销凭证，核销后将失效。切勿将核销码透漏于他人</div>
        </div>
      </van-popup>
    </div>
  </van-pull-refresh>
</template>

<script>
  import OpenApp from '@/apps/activity/components/OpenApp.vue'
  import { getActivityInfoAPI, applyInfoAPI } from '@/apps/activity/api/index'
  import { ImagePreview, Toast } from 'vant'
  import dayjs from 'dayjs'
  import VueQr from 'vue-qr'
  // import { isWeChat } from '@/utils'
  // import dayjs from 'dayjs'
  export default {
    name: 'HomeView',
    components: {
      OpenApp,
      VueQr
    },
    data() {
      return {
        isLoading: false,
        // showOpenOut: false,
        showTicket: false,
        curTicket: null,
        showResult: false,
        info: {},
        applyInfo: {},
        ticketQrcodeUrl: ''
      }
    },
    computed: {
      // 自定义售票时间，且当前时间早于开始售票时间（还未到售票时间
      isBeforeSellStartTime() {
        if (this.info.sellTimeType === 2 && dayjs(this.info.sellStartTime).isAfter(Date.now())) {
          return true
        } else {
          return false
        }
      },
      // 自定义售票时间，且当前时间晚于结束售票时间（售票时间已过）
      isAfterSellEndTime() {
        if (this.info.sellTimeType === 2 && dayjs(this.info.sellEndTime).isBefore(Date.now())) {
          return true
        } else {
          return false
        }
      }
    },
    mounted() {
      // this.showOpenOut = isWeChat()
      this.init()

      // 调试短信查看二维码页面用
      // this.$router.push('/writeOff?id=K7lgwD1f')

      // 如果本地有订单信息，跳转到表单页面，确认订单是否支付完成，如果订单25分钟内未支付则清除订单信息
      // 需求已改，如果支付成功了，则详情页直接显示报名成功，反之则放弃订单
      /* let payInfo = localStorage.getItem('payInfo')
      if (payInfo) {
        payInfo = JSON.parse(payInfo)
        let diff = dayjs().diff(dayjs(payInfo.createTime), 'minute')
        console.log(diff)
        if (diff < 25) {
          this.$router.push(`/form?id=${this.$route.query.id}`)
        } else {
          localStorage.removeItem('payInfo')
        }
      } */
    },
    methods: {
      onRefresh() {
        this.getInfo()
      },
      async init() {
        await this.getInfo()
        // 改为跳转页面展示二维码
        /* if (this.$route.query.qrcode) {
          this.autoShowResult()
        } */
      },
      getInfo() {
        this.isLoading = true
        return getActivityInfoAPI({ id: this.$route.query.id })
          .then((res) => {
            document.title = res.data.title
            this.info = res.data
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      },
      onApply() {
        if (!this.$store.state.userInfo.token) {
          this.$store.commit('UPDATE_SHOW_LOGIN', true)
          this.$bus.$once('after_login', async () => {
            await this.getInfo()
            // 如果未报名
            if (!this.info.apply) {
              this.handleTicket()
            }
          })
        } else {
          this.handleTicket()
        }
      },
      handleTicket() {
        // 只有一个票种的免费活动直接跳转报名表单
        if (this.info.ticketType && this.info.ticketType.length === 1 && this.info.ticketType[0].price === 0) {
          this.$router.push(`/form?id=${this.$route.query.id}&ticketTypeId=${this.info.ticketType[0].id}`)
        } else {
          if (this.info.ticketType && this.info.ticketType.length) {
            this.curTicket = this.info.ticketType[0]
            this.showTicket = true
          } else {
            Toast('无票种信息')
          }
        }
      },
      onChooseTicket(ticket) {
        this.curTicket = ticket
      },
      onNext() {
        if (!this.curTicket) {
          Toast({
            message: '请先选择票种',
            type: 'text'
          })
          return
        } else {
          this.showTicket = false
          this.$router.push(`/form?id=${this.$route.query.id}&ticketTypeId=${this.curTicket.id}`)
        }
      },
      onService() {
        if (window.easemobim) {
          window.easemobim.bind({
            configId: process.env.VUE_APP_CONFIG_ID
          })
        } else {
          Toast.fail('聊天系统加载中!')
        }
      },
      onShare() {
        if (!navigator.share) {
          Toast('您可以使用浏览器的分享功能进行分享！')
        } else {
          navigator.share({
            //要共享的标题
            // title: this.info.title,
            //要共享的 URL
            url: window.location.href,
            //要共享的文本
            text: this.info.title
          })
        }
      },
      onShowResult() {
        this.showResult = true
        this.getApplyInfo()
      },
      autoShowResult() {
        if (!this.$store.state.userInfo.token) {
          this.$store.commit('UPDATE_SHOW_LOGIN', true)
          this.$bus.$once('after_login', async () => {
            await this.getInfo()
            // 如果已报名，且需要核销的票，自动弹出核销码
            if (this.info.apply && this.info.writeOff) {
              this.onShowResult()
            }
          })
        } else {
          if (this.info.apply && this.info.writeOff) {
            this.onShowResult()
          }
        }
      },
      getApplyInfo() {
        applyInfoAPI({ id: this.$route.query.id }).then((res) => {
          this.applyInfo = res.data
        })
      },
      onTicketQrcode(dataUrl) {
        console.log(dataUrl)
        this.ticketQrcodeUrl = dataUrl
      },
      onPreivewTicketQrcode() {
        if (this.applyInfo.writeOff) return
        ImagePreview([this.ticketQrcodeUrl])
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    padding-top: 60px;
    background-color: #f8f8f8;
    padding-bottom: calc(75px + constant(safe-area-inset-bottom)); //兼容 IOS<11.2
    padding-bottom: calc(75px + env(safe-area-inset-bottom)); //兼容 IOS>11.2
  }
  .cover {
    width: 100%;
    height: 210px;
  }
  .info {
    .info-card {
      width: 350px;
      padding: 15px;
      box-sizing: border-box;
      position: relative;
      margin: -38px auto 20px;
      background-color: #fff;
      border-radius: 10px;
      .title {
        margin-bottom: 8px;
        padding: 5px;
        font-size: 16px;
        color: #333333;
      }
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 7px;
        color: #333333;
        font-size: 15px;
        font-weight: 300;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .money {
          font-size: 16px;
        }
      }
    }
    padding: 0 10px;
    .info-content {
      border-radius: 10px;
      overflow: hidden;
      .content {
        width: 100%;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    height: calc(65px + constant(safe-area-inset-bottom)); //兼容 IOS<11.2
    height: calc(65px + env(safe-area-inset-bottom)); //兼容 IOS>11.2
    padding-bottom: constant(safe-area-inset-bottom); //兼容 IOS<11.2
    padding-bottom: env(safe-area-inset-bottom); //兼容 IOS>11.2
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 42px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    .footer-banner {
      position: absolute;
      left: 0;
      bottom: calc(65px + constant(safe-area-inset-bottom)); //兼容 IOS<11.2
      bottom: calc(65px + env(safe-area-inset-bottom)); //兼容 IOS>11.2
      width: 100%;
      height: 45px;
      box-sizing: border-box;
      background: linear-gradient(88deg, #d7d4ff 0%, #eeecff 100%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      .banner-left {
        display: flex;
        .left-icon {
          width: 16px;
          height: 16px;
          margin-right: 25px;
        }
        .left-text {
          font-size: 14px;
          color: #3a347e;
        }
      }
      .banner-btn {
        width: 88px;
        height: 35px;
        background: linear-gradient(134deg, #d1d3ff 0%, #8e76f1 100%);
        border-radius: 18px;
        line-height: 35px;
        font-size: 14px;
        color: #fff;
        text-align: center;
      }
    }
    .footer-tool {
      .tool-img {
        width: 28px;
        height: 28px;
      }
      .tool-text {
        font-size: 14px;
        font-weight: 500;
        color: #6a747e;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 187px;
      height: 52px;
      background: linear-gradient(135deg, #fba28d 0%, #f68a70 100%);
      border-radius: 23px;
      font-size: 19px;
      font-weight: 400;
      color: #ffffff;
    }
    .btn-applied {
      width: 187px;
      height: 52px;
      line-height: 52px;
      background: #a899f0;
      border-radius: 23px;
      text-align: center;
      font-size: 19px;
      font-weight: 500;
      color: #fff;
    }
  }
  .open-out {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    img {
      width: 99%;
    }
  }
  .ticket-popup {
    padding: 20px 25px;
    box-sizing: border-box;
    .title {
      font-size: 18px;
      color: #333333;
      margin-bottom: 8px;
    }
    .tip {
      font-size: 14px;
      color: #ffaa54;
      margin-bottom: 8px;
    }
    .list {
      padding: 10px 0;
      min-height: 100px;
      max-height: 280px;
      overscroll-behavior-y: contain;
      overflow-y: auto;
    }
    .ticket {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fafaff;
      border: 1px solid #e0dfef;
      border-radius: 9px;
      margin-bottom: 12px;
      padding: 0 16px;
      .name {
        font-size: 20px;
        width: 180px;
      }
      .price {
        font-size: 20px;
        margin-bottom: 5px;
        .price-title {
          margin-right: 6px;
          color: #333333;
        }
        .price-num {
          color: #ec808d;
        }
      }
      .oprice {
        font-size: 16px;
        .oprice-title {
          margin-right: 6px;
          color: #b5b5b5;
          text-decoration: line-through;
        }
        .oprice-num {
          color: #b5b5b5;
          text-decoration: line-through;
        }
      }
    }
    .active {
      border: 1px solid #8076f1;
    }
    .tfooter {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #f1f1f1;
      .price {
        font-size: 20px;
        .price-title {
          color: #333333;
        }
        .price-num {
          color: #ec808d;
        }
      }
      .next {
        width: 120px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        background-color: #f68a70;
        border-radius: 28px;
        color: #fff;
        font-size: 20px;
      }
      .next-gray {
        background-color: #9f9f9f;
      }
    }
  }
  .result-popup {
    width: 310px;
    height: 410px;
    padding: 30px 0 20px 0;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    .writeoff {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    .ctime {
      font-size: 12px;
      color: #c5c5c5;
    }
    .line {
      height: 1px;
      margin: 10px 0;
      background-color: #efefef;
    }
    .tname {
      margin-bottom: 12px;
      font-size: 16px;
      color: #8076f1;
    }
    .tqrcode {
      position: relative;
      display: inline-block;
      .tqrcode-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: saturate(180%) blur(8px);
        color: #fff;
        font-size: 16px;
        line-height: 180px;
      }
    }
    .wtime {
      font-size: 16px;
      padding: 15px 30px;
    }
    .ticket-tips {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      bottom: 20px;
      padding: 0px 30px;
      font-size: 12px;
      color: #b8b8b8;
    }
  }
</style>
